import React, { useState } from "react";
import { useCart } from "@/context/cart/CartContext";
import Loader from "../loading-spinner";

const AddToCart = ({
  availableForSale,
  productId,
  productName,
  productPrice,
  productBrand,
  productCategory,
  productVariant,
  className,
  iconColor,
  orderQty,
}) => {
  const [iconStyle, setIconStyle] = useState(iconColor);
  const [adding, setAdding] = useState(false);
  const { addToCart } = useCart();
  const handleAdd = (productId) => {
    setAdding(true);
    addToCart(productId, orderQty).then((data) => {
      if (data) {
        // Track the 'AddToCart' event with gtag
        if (typeof gtag !== "undefined") {
          gtag("event", "add_to_cart", {
            currency: "USD",
            value: productPrice * orderQty,
            items: [
              {
                item_id: productId,
                item_name: productName,
                item_brand: productBrand,
                item_category: productCategory,
                item_variant: productVariant,
                price: productPrice,
                quantity: orderQty,
              },
            ],
          });
        }

        // Track the 'AddToCart' event with fbq
        if (typeof fbq !== "undefined") {
          fbq('track', 'AddToCart', {
            content_ids: [productId], // 'REQUIRED': array of product IDs
            content_type: 'product', // RECOMMENDED: Either product or product_group based on the content_ids or contents being passed.
          });
        }

        setAdding(false);
      } else {
        setAdding(false);
      }
    });
  };

  const handleMouseEnter = () => {
    setIconStyle("#ffffff");
  };

  const handleMouseLeave = () => {
    setIconStyle(iconColor);
  };

  return (
    <div className="sm:max-w-[220px] flex-1 flex relative items-center justify-center overflow-hidden font-medium text-black transition duration-300 ease-out border border-black pl-2 rounded-full shadow-md group">
      <svg
        className="w-4 h-4"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
        ></path>
      </svg>
      <button
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        aria-label="Add item to cart"
        onClick={() => handleAdd(productId)}
        className={`flex-1 text-xs sm:text-sm tracking-tight ${className} ${!availableForSale && "pointer-events-none bg-gray-400 text-white"
          } relative inline-flex items-center justify-center px-1 py-1 sm:px-6 sm:py-2 overflow-hidden font-medium text-black transition duration-300 ease-out rounded-full shadow-md group`}
      >
        <span className="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-gray-300 group-hover:translate-x-0 ease">
          <p className="text-black">Great Choice!</p>
        </span>
        <span className="absolute flex items-center justify-center w-full h-full text-white transition-all duration-300 transform group-hover:translate-x-full ease">
          {availableForSale ? "Add To Cart" : "Request Availability"}
        </span>
        <span className="relative invisible">Great Choice!</span>
        {adding ? <Loader className={"w-4 h-4 absolute right-2"} /> : null}
      </button>
    </div>
  );
};

export default AddToCart;
