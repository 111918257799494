"use client";
import React, { useState, useEffect, useMemo } from "react";
import { useWishList } from "@/context/wishlist/WishListContext";
import "./button.css";
import { faL } from "@fortawesome/free-solid-svg-icons";

const AddToWishList = ({ productId, productName, productPrice,productCategory, style }) => {
  const { wishList, addWishList, deleteWishList } = useWishList();
  const [isSpinning, setIsSpinning] = useState(false);
  const [isMouseIn, setIsMouseHover] = useState(false);

  const inWishlist = useMemo(() => {
    return wishList?.some((item) => item.id === productId);
  }, [wishList, productId]);

  useEffect(() => {
    if (isSpinning) {
      setTimeout(() => {
        setIsSpinning(false);
      }, 1000);
    }
  }, [isSpinning]);

  const handleClick = (id, type) => {
 
    setIsSpinning(true);
    if (wishList?.some((item) => item.id === id && item.type === type)) {
      deleteWishList(id);
    } else {
      addWishList(id, type).then((message) => {
        console.log(message);
        // Trigger the gtag event
        gtag("event", "add_to_wishlist", {
          currency: "INR",
          value: productPrice,
          items: [
            {
              item_id: id,
              item_name: productName,
              item_category: productCategory,
              price: productPrice,
              quantity: 1,
            },
          ],
        });
      });
    }
  };

  const isLiked = useMemo(() => {
    return wishList?.some((item) => item.id === productId && item.type === "like");
  }, [wishList, productId]);

  const isHearted = useMemo(() => {
    return wishList?.some((item) => item.id === productId && item.type === "heart");
  }, [wishList, productId]);

  const isSaved = useMemo(() => {
    return wishList?.some((item) => item.id === productId && item.type === "save");
  }, [wishList, productId]);

  return (
    <div
      onMouseEnter={() => setIsMouseHover(true)}
      onMouseLeave={() => setIsMouseHover(false)}
      className={`${
        isMouseIn
          ? "wishlist-container pl-4 bg-white drop-shadow-sm mr-0 transition ease-in-out duration-300"
          : "mr-2"
      } group flex flex-row space-x-1 items-center hover:pr-2 transition ease-in-out duration-50 p-1`}
    >
      <button
        onClick={() => {
          handleClick(productId, "save");
        }}
        disabled={isSpinning}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill={`${isSaved ? "black" : "none"}`}
          viewBox="0 3 24 24"
          strokeWidth="1.2"
          stroke="currentColor"
          className={`w-6 h-6 ${isSaved ? "text-black" : "text-black"}`}
          style={{ zIndex: inWishlist ? 10 : 1 }}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z"
          />
        </svg>
      </button>
      <div className="group-hover:flex group-hover:transition group-hover:ease-in-out group-hover:duration-100 flex-row space-x-1 items-center hidden">
        <button
          onClick={() => {
            handleClick(productId, "like");
          }}
          disabled={isSpinning}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill={`${isLiked ? "#3b82f6" : "none"}`}
            viewBox="0 0 24 24"
            strokeWidth={1.2}
            stroke="currentColor"
            className={`w-6 h-6 ${isLiked ? "text-blue-500" : "text-black"}`}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.633 10.25c.806 0 1.233-.446 2.031-1.08a9.041 9.041 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V2.75a.75.75 0 0 1 .75-.75 2.25 2.25 0 0 1 2.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282m0 0h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23H5.904m10.598-9.75H14.25M5.904 18.5c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 0 1-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 9.953 4.167 9.5 5 9.5h1.053c.472 0 .745.556.5.96a8.958 8.958 0 0 0-1.302 4.665c0 1.194.232 2.333.654 3.375Z"
            />
          </svg>
        </button>

        <button
          onClick={() => {
            handleClick(productId, "heart");
          }}
          disabled={isSpinning}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill={`${isHearted ? "#F91780" : "none"}`}
            viewBox="0 0 24 24"
            strokeWidth={1.2}
            stroke="currentColor"
            className={`w-6 h-6 ${isHearted ? "text-[#F91780]" : "text-black"}`}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default AddToWishList;
