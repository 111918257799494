"use client";
import React, { useState, useMemo } from "react";
import AddToCart from "./AddToCard";
import AddToWishList from "../wishlist/AddToWishList";
import BuyNow from "./BuyNow";
import Link from "next/link";
const Card = (props) => {
  const {
    _id,
    product_images,
    item_name,
    item_slug,
    brand,
    country,
    inventory,
    awards,
    in_wishlist,
  } = props.product;
  const type = props.type;
  const images = useMemo(() => {
    if (!product_images) return [];
    return Object.values(product_images).filter((image) => image);
  }, [product_images]);
  const [img, setImg] = useState(
    images[Math.floor(Math.random() * images.length + 1)] ||
    product_images?.main_img
  );

  const handleMouseEnter = () => {
    setImg(
      images[Math.floor(Math.random() * images.length + 1)] ||
      product_images?.main_img
    );
  };
  const handleMouseLeave = () => {
    setImg(
      images[Math.floor(Math.random() * images.length + 1)] ||
      product_images?.main_img
    );
  };

  const redirectLink = `/product/${item_slug}/buy/${_id}`;
  const brandPageLink = `/brand/${brand?.brand_name?.toLowerCase()}/home?brand_id=${brand?._id}`;
  return (
    <div
      onMouseEnter={() => handleMouseEnter()}
      onMouseLeave={() => handleMouseLeave()}
      className="relative w-full max-h-fit flex flex-col justify-between shadow rounded-md hover:scale-[1.02] hover:duration-500"
    >
      <div className="absolute  -right-1 top-0">
        <AddToWishList productCategory={inventory?.parent_category} productPrice={inventory?.list_price} productName={item_name} productId={_id} style={" rounded-full bg-white"} />
      </div>
      <div className="w-full h-40  sm:h-52 md:h-80 rounded-tl-md rounded-tr-md ">
        <Link target="_blank" href={redirectLink}>
          <img
            loading="lazy"
            className="w-full h-full object-cover rounded-tl-md rounded-tr-md animate-fade-in "
            src={img}
            width={500}
            height={500}
            alt=""
          />
        </Link>
      </div>
      <div className="flex flex-col p-2 sm:p-4">
        <div className=" mt-1 w-7 h-7  overflow-hidden">
          <Link
            target="_blank"
            href={brandPageLink}
          >
            <img
              loading="lazy"
              className="w-full h-full rounded-full drop-shadow  object-contain"
              src={brand?.brand_logo_url}
              alt=""
            />
          </Link>
        </div>
        <div className="flex items-center gap-2">
          <Link
            target="_blank"
            href={brandPageLink}
          >
            {brand?.brand_name}
          </Link>
          <img
            loading="lazy"
            className="w-5 h-5 rounded-full"
            src={country?.image}
            alt={country?.name}
          />
        </div>
        <Link
          target="_blank"
          href={redirectLink}
          className=" text-[12px] sm:text-sm font-sans text-gray-900  hover:text-blue-500"
        >
          {item_name}
        </Link>
        <div className="flex justify-start gap-2">
          <p href="#" className="text-[12px] sm:text-sm font-medium">
            ₹ {inventory?.list_price}
          </p>
          <p href="#" className=" text-gray-400 text-[12px] sm:text-sm line-through">
            {inventory?.discount_percent ? (
              <>{"₹" + inventory?.max_retail_price}</>
            ) : null}
          </p>
          {inventory?.discount_percent != 0 && (
            <p className="text-[#068743] text-[12px] sm:text-sm">
              {inventory?.discount_percent}%
            </p>
          )}
        </div>
        <div className="flex flex-row items-center justify-between pt-1 space-x-2  gap-1">
          <div className="flex gap-1 sm:gap-2">
            {awards &&
              awards.map((award, index) => {
                if (index < 3) {
                  return (
                    <img
                      loading="lazy"
                      key={index}
                      className="w-3 h-3 sm:w-5 sm:h-5 rounded-full object-contain"
                      src={award?.image}
                      alt={award?.award_name}
                    />
                  );
                }
              })}
          </div>
          {
            type === 'buyNow' ? <BuyNow
              productId={_id}
              availableForSale={inventory?.qty_available}
              orderQty={1}
            />
              : <AddToCart
                key={_id}
                productId={_id}
                orderQty={1}
                productName={item_name}
                productPrice={inventory?.list_price}
                productBrand={brand.brand_name}
                productCategory={country.country_name}
                availableForSale={inventory?.qty_available}

                className="text-xs  bg-black text-white rounded hover:bg-white hover:text-black"
                iconColor={`#3A4980`}
              />
          }

        </div>
      </div>
    </div>
  );
};
export default Card;
