"use client";
import React, { useCallback, useState } from "react";
import { addToCartWish } from "@/context/cart/api";
import Loader from "../loading-spinner";
import { useRouter } from "next/navigation";
import { useCart } from "@/context/cart/CartContext";
import { useAuth } from "../../context/AuthContext";
import getToken from "@/hook/getToken";

const BuyNow = ({ productId, availableForSale, orderQty }) => {
  const router = useRouter();
  const token = getToken();
  const apiUrl = token ? "/v1/cart/add" : "/v1/cart/add-quick-buy";
  const { isBuyNow, setIsBuyNow, setCart, setBuyItems } = useCart();
  const { setIsSignUpOpen, setIsSignInOpen } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const handleBuyNow = async (id) => {
    const body = {
      product_id: id,
      order_qty: orderQty,
      buy_now: true,
    };
    const res = await addToCartWish(apiUrl, body).then((res) => res.json());
    console.log("quick buy res", res);
    if (res.success) {
      const response = token
        ? res?.response[0].pricing
        : res?.cart_products[0]?.pricing;
      const sessionId = res?.session_id;
      if (sessionId) {
        localStorage.setItem("sessionId", sessionId);
      } else {
        localStorage.removeItem("sessionId");
      }

      if (!token && res) {
        setBuyItems(res);
      }
      const { max_retail_price, list_price, discount } = response;
      const encodedMaxRetailPrice = encodeURIComponent(max_retail_price);
      const encodedListPrice = encodeURIComponent(list_price);
      const encodedDiscount = encodeURIComponent(discount);
      setIsLoading(false);
      if (token) {
        setIsBuyNow((pre) => !pre);
      }
      router.push(
        `/checkout?buynow=true&max_retail_price=${encodedMaxRetailPrice}&list_price=${encodedListPrice}&discount=${encodedDiscount}`
      );
    }
  };

  return (
    <button
      onClick={() => handleBuyNow(productId)}
      type="button"
      className={`border border-solid border-white text-sm font-sans flex-1 flex justify-center items-center ${!availableForSale
        ? "bg-red-400 text-white pointer-events-none"
        : "bg-black text-white font-semibold"
        } hover:bg-black hover:text-white py-2 rounded-full cursor-pointer transition-all duration-300 transform ${!availableForSale ? "" : "hover:scale-105"
        }`}
      disabled={!availableForSale}
    >
      Buy Now{isLoading ? <Loader className={"w-4 h-4"} /> : null}
    </button>
  );
};

export default BuyNow;
